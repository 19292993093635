@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';

.Contact {
    background: linear-gradient(180deg, #f2f5fa 0%, hsl(0, 0%, 100%) 100%) !important;

    h2 {
        color: $color_brand !important;
    }

    .row {
        display: block;

        margin: $dims_padding-medium 0;

        @include respond-to(tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $dims_padding-large;
        }
    }

    .ContactForm {
        margin-top: $dims_padding-medium;

        @include respond-to(tablet) {
            margin-top: 0;
        }
    }

    .engagement-content {
        display: flex;
        align-items: center;
        padding: 1rem;
        height: 100%;

        text-align: center;

        background: url(../../assets/contact-banner.png);
        background-position: center center;
        background-size: cover;
        border-radius: $box_radius-medium;
        overflow: hidden;
        box-shadow: layout.$box-shadow--large;

        width: 100%;
        margin-top: $dims_padding-medium;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            width: 100%;
            height: 100%;

            background: linear-gradient(to right, #{rgba(#FBFAF4, 1)}, #{rgba(#FBFAF4, .25)});
        }

        @include respond-to(tablet) {
            margin-top: 0;
        }

        @include respond-to(desktop) {
            width: auto;
            height: 100%;
            padding: 2rem;
        }

        > * {
             z-index: 1;
         }

         .inner-content {
            max-width: 20em;
            margin: auto;
            text-align: left;
         }

        .engagement-blurb {
            color: $color_black;
            padding: $dims_padding-small * 2;

            background: color.$white;
            border-radius: $box_radius-medium;
            border: 2px solid $color_black;

            &:after {
                border-top-color: #e5e5ff;
                border-left-color: #e5e5ff;
            }

            h4 {
                margin: 0;
                margin-bottom: $dims_padding-small;

                font-size: $font_size-medium;
                font-weight: 500;
                color: $color_black;
            }

            p {
                margin: 0;

                font-size: $font_size-small;
            }
        }

        .button {
            display: inline-block;
            margin-top: 2rem;

            color: $color_font-light;

            background: $color_brand;
            border-radius: $box_radius-large;
            transition: background .2s;
            padding: $dims_padding-small $dims_padding-small * 2;

            &:hover {
                background: darken($color_brand--secondary, 5);
                transition: background .2s;
            }
        }
    }

    .privacy {
        font-size: .75rem !important;
        color: #999;
    }

}
