.Pin {
    border-radius: 50%;
    display: inline-block;
    z-index: 10;

    &:after {
        content: '';
        display: block;
        position: absolute;
        margin: auto;
        border-radius: 5px;
        height: 45px;
        width: 50px;
        background: #eee;
        transform: rotate(60deg) skew(30deg);
        top: 24px;
        left: 6px;
        z-index: -1;
    }

    img {
        width: 60px;
        padding: 5px;
        display: block;
        background: #eee;
        border-radius: 50%;
        z-index: 2;
    }

    span {
        display: inline-block;
        position: absolute;
        top: 10px;
        left: 40px;
        z-index: 0;

        width: max-content;
        padding: 7px 20px 7px 30px;

        background: #eee;
        border-radius: 100px;
    }
}

.Callout {
    display: inline-block;
    background: #aaa;
    padding: 15px 30px;
    text-align: center;
    border-radius: 100px;

    &:before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        bottom: -.4em;
        right: .45em;

        border-top: #aaa solid .5em;
        border-left: transparent solid .5em;
        border-right: transparent solid .5em;
    }

}
