@use 'sass:list';
@use 'sass:math';
@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.Process {

    h2 {
        color: $color_brand !important;
        padding-bottom: $dims_padding-small;
    }

    $colors:  $color_brand, $color_brand, $color_brand, $color_brand;
    $widths:  33%,    33%,     34%,     100%;
    $offsets: 0%,      33%,      66%,     0%;

    .timeline {
        .block {
            padding: $dims_padding-small;

            text-align: center;

            border-radius: layout.$border-radius--large;
            box-shadow: layout.$box_shadow--large;
            cursor: pointer;
            transition: background animation.$speed;

            @for $i from 1 through length($colors) {
                &:nth-of-type(#{$i}) {
                    left: list.nth($offsets, $i);

                    width: list.nth($widths, $i);

                    background: rgba(list.nth($colors, $i), .05);
                    border: 4px solid list.nth($colors, $i);

                    &.active {
                        background: rgba(list.nth($colors, $i), .35);
                    }
                }
                &:last-of-type {
                    pointer-events: none;
                }
            }

            + .block {
                margin-top: $dims_padding-small;
            }

            h4, h5 {
                margin: 0;

                font-size: $font_size-small;
            }

            h5 {
                font-weight: 400;
            }

            h4 + h5 {
                margin-top: $dims_padding-small * .6;
            }
            
            h5 {
                display: none;
                @include respond-to(desktop) {
                display: block;
            }
            }
            
        }
    }

    .Accordion {


        .header {
            display: none;
            @include respond-to(desktop) {
                display: block;
            }
            @include respond-to(tablet) {
                display: block;
            }

        }
        main {
            display: flex;
            flex-direction: column;

            justify-content:center ;
            align-items: flex-start;
            h3 {
                margin: 0;
                font-size: $font_size-small !important;
            }

            p {
                margin: math.div($dims_padding-small, 2) 0 $dims_padding-small * 2;
                line-height: 1.4em !important;
            }
            img {
                
                
                
                @include respond-to(desktop) {
                    flex-direction: row-reverse;
                    max-width: 35vw;
                    padding: $dims_padding-medium;
                padding-top: 0;
                padding-right:  $dims_padding-small;
                
                }
                @include respond-to(tablet) {
                    max-width: 35vw;
                    padding: $dims_padding-medium;
                padding-top: 0;
                padding-right:  $dims_padding-small;
                }

                width: 100%;
            }

            @include respond-to(desktop) {
                flex-direction: row-reverse;
            
            }
            @include respond-to(tablet) {
                flex-direction: row-reverse;
                
            }
            
        }
    }

    .share-link {
        display: block;

        margin: $dims_padding-small 0;

        color: $color_brand;
        text-decoration: underline;
    }

}
